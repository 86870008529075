<!-- 预制菜订餐页面 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">预制菜订餐</div>
    </header>
    <section class="page-info">
      <div style="margin:20px">
        <div class="order-attention" @click="handleShowAttention">
          <div class="notice-style">
            时间:早餐06:00-10:30 午餐08:00-14:30 晚餐06:00-21:00
          </div>
          <div class="notice-style">
            公告:下单后系统将于当天下午自动在住院费里扣费，如有更改或取消请提前联系订餐电话82166924，82166924，82166924，82166924
          </div>
        </div>
      </div>
      <div class="meal-detail">
        <div class="meal-type-tabs">
          <div>
            <ChoosePrefab
              :dishes-detail="shoppingCardList.find(o=>o.meal_mode==meal_mode)"
              @upd-shopping-card="updShoppingCard"
            />
          </div>
        </div>
        <div class="summit">
          <van-action-bar>
            <van-action-bar-icon icon="cart-o" text="购物车" :badge="shoppingGoodsTotal" @click="onClickShoppingCard" />
            <van-action-bar-icon v-if="shoppingTotalPrice > 0">
              <template #default>
                <div style="font-size:16px;color:#ee0a24;">
                  ￥{{ shoppingTotalPrice }}
                </div>
              </template>
            </van-action-bar-icon>
            <van-action-bar-button color="#be99ff" type="warning" text="营养分析" @click="onNutritionShow" />
            <van-action-bar-button color="#7232dd" type="danger" text="去下单" @click="onSubmit" />
          </van-action-bar>
        </div>
      </div>
    </section>
  </div>
  <van-dialog v-model:show="moreShow">
    <div class="goods-info-body">
      <div class="goods-info-header">
        营养分析
      </div>
      <div v-if="analysisLoading" class="goods-info-content">
        <div>
          能量:{{moreDetail.energy}}kcal
        </div>
        <div>
          蛋白质:{{moreDetail.protein}}g
        </div>
        <div>
          脂肪:{{moreDetail.fat}}g
        </div>
        <div>
          碳水化合物:{{moreDetail.carbon}}g
        </div>
      </div>
      <div v-else class="goods-info-content analysis">
        <van-loading type="spinner" vertical>
          分析中...
        </van-loading>
      </div>
    </div>
  </van-dialog>
  <van-action-sheet
    v-model:show="shoppingCardShow"
    title="购物车"
    z-index='33'
    style="padding-bottom: 60px;"
  >
    <div class="shopping-card-content">
      <div v-if="shoppingCardList.length > 0">
        <div v-for="(mode, index) in shoppingCardList" :key="index" class="meal-mode-item">
          <!-- <div class="meal-mode-str">
            <div v-if="mode.meal_mode==1">早餐</div>
            <div v-else-if="mode.meal_mode==2">午餐</div>
            <div v-else-if="mode.meal_mode==3">晚餐</div>
            <div v-else>其他</div>
          </div> -->
          <div>
            <div v-for="(dish, dIndex) in mode.dishes_list" :key="dIndex">
              <van-card
                  :num="dish.dishes_num"
                  :price="dish.pay_price"
                  :title="dish.dishes_name"
                  :thumb="require('@/assets/image/dish.png')"
                >
                  <template #tags>
                    <span class="tags-class" v-for="(tag_item, tag_index) in dish.nutrite_data" :key="tag_index">
                      <van-tag
                        plain
                        type="primary"
                      >
                        {{ tag_item.nutrite_name }}
                      </van-tag>
                    </span>
                  </template>
                  <template #num>
                    <div class="pay-num-opt">
                      <van-button v-if="dish.dishes_num > 0" plain icon="minus" round type="danger" size="mini" @click="subShoppingCart(mode, dish)" />
                      <van-button v-if="dish.dishes_num > 0" plain hairline type="default" size="mini" disabled><span style="font-size:16px">{{dish.dishes_num}}</span></van-button>
                      <van-button icon="plus" round type="danger" size="mini" color="#9F88FF" @click="addShoppingCart(dish)" />
                    </div>
                  </template>
                </van-card>
              </div>
          </div>
        </div>
      </div>
      <div v-else class="shopping-card-none">
        <van-empty image-size="10vh" image="error" description="暂无数据" />
      </div>
    </div>
  </van-action-sheet>
  <van-action-sheet
    v-model:show="show"
    title="注意"
    closeable
    round
  >
    <div class="attention-show-content">
      <div class="show-info">
        <div class="show-info-header">
          <van-icon name="underway-o" size="20px" />
          时间
        </div>
        <div class="time-table">
          <van-row>
            <van-col span="6" />
            <van-col span="9">订餐截止时间</van-col>
            <van-col span="9">退餐截止时间</van-col>
            <van-col span="6">早餐</van-col>
            <van-col span="9">今日00:00-10:30</van-col>
            <van-col span="9">明日00:00-00:00</van-col>
            <van-col span="6">午餐</van-col>
            <van-col span="9">今日00:00-14:30</van-col>
            <van-col span="9">明日00:00-00:00</van-col>
            <van-col span="6">晚餐</van-col>
            <van-col span="9">今日00:00-00:00</van-col>
            <van-col span="9">明日00:00-00:00</van-col>
          </van-row>
        </div>
      </div>
      <div class="show-info">
        <div class="show-info-header">
          <van-icon name="orders-o" size="20px" />
          公告
        </div>
        <div class="attention-notice">
          公告:下单后系统将于当天下午自动在住院费里扣费，如有更改或取消请提前联系订餐电话82166924，82166924，82166924，82166924
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import shopCart from "@/api/ofs/shopCart";
import { getStorage } from '@/utils/localStorageUtils';
import CommonData from '@/utils/commonData';
import { Toast } from 'vant';
import ChoosePrefab from "./component/choose.prefab.vue";

export default {
  name: "makePrefabOrder",
  components: {
    ChoosePrefab
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options!=null && options.type != null) {
        data.type = options.type
      }
      getShopCart()
    });

    const data = reactive({
      show: false,
      meal_mode: 1,
      type: 1, // 1 今日订餐 2 明日订餐
      shoppingCardShow: false,
      shoppingGoodsTotal: 0,
      shoppingCardList: [],
      // 营养分析
      moreShow: false,
      moreDetail: {},
      analysisLoading: false,
      // 购物车支付金额
      shoppingTotalPrice: 0.0,
      noEditShopCart: true,
      sucCartLoading:false,
    });

    // 点餐类型 1、 食堂菜品点餐 2、 预制菜定个餐
    const shop_type = ref(2)

    // 监听数字变化
    watch(() => data.shoppingCardList, (newVal) => {
      console.log("watchh!!!!!!!!!!!!",data.noEditShopCart)
      var totalPrice = 0.0
      var total = 0
      if (newVal != null && newVal.length > 0) {
        newVal.map(meal_mode_item => {
          if (meal_mode_item.dishes_list != null && meal_mode_item.dishes_list.length > 0) {
            meal_mode_item.dishes_list.map(dishes_item => {
              totalPrice += dishes_item.dishes_num * parseFloat(dishes_item.member_price)
            })
            total += meal_mode_item.dishes_list.length
          }
        })
      }
      data.shoppingTotalPrice = totalPrice.toFixed(2)
      data.shoppingGoodsTotal = total
      if (data.noEditShopCart) {
        data.noEditShopCart = false
        return
      }
      editShoppingCard()
    },
    {deep:true}
    )

    const getShopCart = () => {
      data.sucCartLoading = false
      var shopCartList = []
      var dishes_1 = []
      const post_data = {
        shop_type: shop_type.value,
        order_type: data.type,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID)
      }
      shopCart.getShopCartList(post_data).then(res=> {
        if (res.data != null && res.data.length > 0) {
          res.data.map(item => {
            item.prefab_id = item.dishes_id
            item.member_price = (parseFloat(item.member_price)).toFixed(2)
            item.pay_price = (item.dishes_num * parseFloat(item.member_price)).toFixed(2)
            item.nutrite_data = item.food_list
            dishes_1.push(item)
          })
        }
        if (dishes_1.length > 0) {
          shopCartList.push({
            meal_mode: 1,
            dishes_list: dishes_1.sort(function(a, b) { return b.dishes_id - a.dishes_id })
          })
        }
        data.shoppingCardList = shopCartList
        data.sucCartLoading = true
      }).catch(err => {
        data.sucCartLoading = true
      })
    }

    const handleShowAttention = () => {
      data.show=true
    }

    const onNutritionShow = () => {
      if (data.shoppingGoodsTotal <= 0) {
        Toast("购物车无数据！")
        return
      }
      data.analysisLoading = false
      data.moreShow = true
      var energy = 0.0
      var fat = 0.0
      var protein = 0.0
      var carbon = 0.0
      // 循环购物车计算
      data.shoppingCardList.map(item => {
        var dishes_list = item.dishes_list
        dishes_list.map(d_item => {
          energy += d_item.energy
          fat += d_item.fat
          protein += d_item.protein
          carbon += d_item.carbon
        })
      })
      data.moreDetail = {
        energy: energy.toFixed(2),
        fat: fat.toFixed(2),
        protein: protein.toFixed(2),
        carbon: carbon.toFixed(2),
      }
      data.analysisLoading = true
    }
    const onSubmit = () => {
      console.log("提交订单!")
      if (data.shoppingGoodsTotal <= 0) {
        Toast("购物车无数据，无法下单！")
        return
      }
      Toast.loading({
        message: '下单中...',
        forbidClick: true,
        duration: 0
      });
      setTimeout(() =>{
        Toast.clear()
        const params = {
          order_type: data.type,
          shop_type: shop_type.value,
        }
        $router.push({
          query: params,
          name: "doOrder"
        })
      },1000)
    }

    const addShoppingCart = item => {
      console.log(item)
      item.dishes_num += 1
      item.pay_price = (item.dishes_num * parseFloat(item.member_price)).toFixed(2)
    }
    const subShoppingCart = (list,item) => {
      console.log(item)
      item.dishes_num -= 1
      item.pay_price = (item.dishes_num * parseFloat(item.member_price)).toFixed(2)
      // 如果减到0了  直接从购物车删除
      if (item.dishes_num == 0) {
        var index_config = list.dishes_list.map(e => { return e.dishes_id }).indexOf(item.dishes_id)
        if (index_config >= 0) {
          list.dishes_list.splice(index_config, 1)
        }
        // 如果此时dishes_list为空  需从购物车删除
        if (list.dishes_list == null || list.dishes_list.length == 0) {
          popShoppingCart(list.meal_mode)
        }
      }
    }

    const popShoppingCart = meal_mode => {
      console.log(meal_mode)
      var index_config = data.shoppingCardList.map(e => { return e.meal_mode }).indexOf(meal_mode)
      if (index_config >= 0) {
        data.shoppingCardList.splice(index_config, 1)
      }
    }

    const onClickShoppingCard = () => {
      data.shoppingCardShow = true
    }
    const editShoppingCard = () => {
      var dishes_list = []
      data.shoppingCardList.map(item => {
        item.dishes_list.map(d_item => {
          var dish_info = {
            dishes_id: d_item.prefab_id,
            meal_mode: item.meal_mode,
            dishes_num: d_item.dishes_num
          }
          dishes_list.push(dish_info)
        })
      })
      var post_data = {
        shop_type: shop_type.value,
        order_type: data.type,
        dishes_list: JSON.stringify(dishes_list),
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID)
      }
      console.log(post_data)
      shopCart.editShopCart(post_data)
    }
    const updShoppingCard = e => {
      console.log(e)
      e.pay_price = (e.dishes_num * parseFloat(e.member_price)).toFixed(2)
      console.log(data.meal_mode)
      if (data.shoppingCardList == null || data.shoppingCardList.length == 0) {
        // 直接生成添加
        var dishes_list = []
        dishes_list.push(e)
        var mealModeList = {
          meal_mode: data.meal_mode,
          dishes_list: dishes_list
        }
        data.shoppingCardList.push(mealModeList)
      } else {
        // 非空时  需要先找到相关的餐次
        var findMealModeList = data.shoppingCardList.find(o => o.meal_mode == data.meal_mode)
        if (findMealModeList == null || findMealModeList.length == 0) { // 找到了餐次数据为空 添加
          var dishes_list = []
          dishes_list.push(e)
          var mealModeList = {
            meal_mode: data.meal_mode,
            dishes_list: dishes_list
          }
          data.shoppingCardList.push(mealModeList)
        } else {
          // 现找到目前的菜品信息
          var dishes_info = findMealModeList.dishes_list.find(o => o.dishes_id == e.dishes_id)
          if (dishes_info == null || dishes_info.length==0) {
            findMealModeList.dishes_list.push(e)
          } else {
            dishes_info.dishes_num = e.dishes_num
            dishes_info.pay_price = (e.dishes_num * parseFloat(e.member_price)).toFixed(2)
          }
        }
      }
      data.shoppingCardList.sort(function(a, b) { return a.meal_mode - b.meal_mode })
    }

    return {
      ...toRefs(data),
      handleShowAttention,
      onNutritionShow,
      onSubmit,
      onClickShoppingCard,
      updShoppingCard,
      addShoppingCart,
      subShoppingCart
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    height: 100%;
    .order-attention {
      margin: 20px 0;
      color: #AAAAAA;
      font-size: 14px;
      line-height: 24px;
      .notice-style {
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
    }
    .summit {
      z-index: 100;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.goods-info-body {
  max-height: 50%;
  min-height: 150px;
  height: 100%;
  margin: 20px;
  .goods-info-header {
    text-align: center;
    font-size: 16px;
    line-height: 35px;
    font-weight: bold;
  }
  .goods-info-content {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }
  .analysis {
    height: 150px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
}
.attention-show-content {
  min-height: 66vh;
  .show-info {
    margin: 0 10px;
    .show-info-header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      color: #9932CC;
      font-size: 20px;
      line-height: 30px;
    }
    .time-table {
      margin: 10px 0;
      font-size: 14px;
    }
    .attention-notice {
      margin: 10px 0;
      font-size: 14px;
    }
  }
}

.shopping-card-content {
  padding: 16px;
  min-height: 33vh;
  max-height: 53vh;
  .tags-class {
    margin: 0 5px 3px 0;
  }
  .tags-class :last-child{
    margin: 0 0 3px 0;
  }
  .shopping-card-none {
    height: 100%;
    font-size: 18px;
    color: #AAAAAA;
  }
  .meal-mode-item {
    margin: 0;
    padding-bottom: 20px;
    .meal-mode-str {
      font-size: 16px;
      font-weight: bold;
      padding: 0 0 10px 0;
    }
  }
}

</style>
