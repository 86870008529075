import api from '../index'

const prefab = {
    // 获取预制菜列表
    getPrefabCate(data) {
        return api({
            url: 'v2/ofs/prefab-cate',
            method: 'post',
            data: data
        })
    },
    getPrefabList(data) {
        return api({
            url: 'v2/ofs/prefab-list',
            method: 'post',
            data: data
        })
    },

}
export default prefab
