<!-- 菜品信息 -->
<template>
  <div class="page-main">
    <div class="page-info">
      <van-sidebar
        v-model="active"
        @change="onChange"
      >
        <van-sidebar-item v-for="item in cateList" :key="item.cate_id" :title="item.cate_name" />
      </van-sidebar>
      <div class="sidebar-detail">
        <div v-if="tableList.length>0">
          <van-list
            v-model:loading="loading"
            :finished="tableList.length==total"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell v-for="(item, index) in tableList" :key="index">
              <div class="dish-item" style="position:relative;">
                <van-image v-if="index <= 4"
                  width="100%"
                  fit="fill"
                  style="z-index:1;left:0;top:0;position:absolute;width:40px;"
                  :src="require('@/assets/image/ico-recom.png')"
                />
                <van-card
                  :num="item.dishes_num"
                  :price="item.member_price"
                  :title="item.dishes_name"
                  :thumb="require('@/assets/image/dish.png')"
                  style="padding:0 0 10px 0;"
                >
                  <template #tags>
                    <span class="tags-class" v-for="(tag_item, tag_index) in item.nutrite_data" :key="tag_index">
                      <van-tag  plain type="primary">{{ tag_item.nutrite_name }}</van-tag>
                    </span>
                  </template>
                  <template #num>
                    <div class="pay-num-opt">
                      <van-button v-if="item.dishes_num > 0" plain icon="minus" round type="danger" size="mini" @click="subShoppingCart(item)" />
                      <van-button v-if="item.dishes_num > 0" plain hairline type="default" size="mini" disabled><span style="font-size:16px">{{item.dishes_num}}</span></van-button>
                      <van-button icon="plus" round type="danger" size="mini" color="#9F88FF" @click="addShoppingCart(item)" />
                    </div>
                  </template>
                </van-card>
                <div class="nutrition-decs">
                  <div class="decs-nutrition-detail">
                    能量：{{item.energy}}kcal  蛋白质:{{item.protein}}g 脂肪：{{item.fat}}g 碳水化合物：{{item.carbon}}g
                  </div>
                  <!-- <div class="need-know-more" @click="handleKnowMore(item)">
                    了解更多>
                  </div> -->
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div v-else class="shopping-card-none">
          <div v-if="fetchLoading" class="shopping-card-loading">
            <van-loading size="24px" vertical>加载中...</van-loading>
          </div>
          <div v-else>
            <van-empty image-size="10vh" image="error" description="暂无数据" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-dialog v-model:show="moreShow">
    <div class="goods-info-body">
      <div class="goods-info-header">
        营养详情
      </div>
      <div class="goods-info-content">
        <div>
          能量:{{moreDetail.energy}}kcal
        </div>
        <div>
          蛋白质:{{moreDetail.protein}}g
        </div>
        <div>
          脂肪:{{moreDetail.fat}}g
        </div>
        <div>
          碳水化合物:{{moreDetail.carbon}}g
        </div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import prefab from '@/api/ofs/prefab'

export default {
  name: "choosePrefab",
  components: {},
  emits: ['updShoppingCard'],
  props: {
    dishesDetail: {
      type: Array,
      default: null
    }
  },
  setup(props, setupContext) {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      getCateList()
    });

    const data = reactive({
      active: 0,
      cateList: [],
      loading: false,
      total: 0,
      tableList: [],
      page_index: 1,
      page_size: 10,
      moreShow: false,
      moreDetail: {},
      fetchLoading: true
    });

    const getCateList = () => {
      prefab.getPrefabCate({}).then(res=>{
        if (res.data != null) {
          data.cateList = res.data
          getPrefabList()
        }
      })
    }

    const getPrefabList = () => {
      console.log(data.active)
      var cate_id = data.cateList[data.active].cate_id
      const post_data = {
        cate_id: cate_id,
        page_index: data.page_index,
        page_size: data.page_size
      }
      data.fetchLoading = true
      prefab.getPrefabList(post_data).then(res=>{
        if (res.data != null && res.data.total > 0) {
          data.total = res.data.total
          const tableData = res.data.data.map(item => {
            item.dishes_num = 0
            item.member_price = parseFloat(item.sale_price).toFixed(2)
            item.dishes_name = item.prefab_name
            item.dishes_id = item.prefab_id
            if (
              props.dishesDetail != null
              && props.dishesDetail.dishes_list != null
              && props.dishesDetail.dishes_list.length > 0
            ) {
              var findDishInfo = props.dishesDetail.dishes_list.find(o => o.dishes_id == item.dishes_id)
              if (findDishInfo != null) {
                item = findDishInfo
              }
            }
            return item
          })
          if (data.page_index == 1) {
            data.tableList = tableData
          } else {
            data.tableList = [...data.tableList, ...tableData]
          }
        } else {
          data.tableList = []
        }
        data.loading = false
        data.fetchLoading = false
      }).catch(res => {
        data.fetchLoading = false
      })
    }

    const onChange = e => {
      console.log("onChange", e)
      data.active = e
      data.page_index = 1
      data.total = 0
      data.loading = false
      data.tableList = []
      getPrefabList()
    }

    const onLoad = () => {
      console.log("加载更多！")
      data.page_index += 1
      getPrefabList()
    }

    const handleKnowMore = item => {
      data.moreDetail = item
      data.moreShow = true
    }

    const addShoppingCart = item => {
      item.dishes_num += 1
      proxy.$emit('updShoppingCard', item)
    }
    const subShoppingCart = item => {
      item.dishes_num -= 1
      proxy.$emit('updShoppingCard', item)
    }

    return {
      ...toRefs(data),
      handleKnowMore,
      addShoppingCart,
      subShoppingCart,
      onChange,
      onLoad
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  margin: 10px 0;
  .page-info {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 80px;
    .sidebar-detail {
      width: 100%;
      :deep(.van-cell:after) {
        border: none;
      }
      .dish-item {
        background: #fafafa;
        // margin: 10px 0;
        .tags-class {
          margin: 0 5px 3px 0;
        }
        .tags-class :last-child{
          margin: 0 0 3px 0;
        }
        .nutrition-decs {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          // padding: 10px 5px 0 10px;
          .decs-nutrition-detail {
            // max-width: 210px;
            // overflow:hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // -o-text-overflow:ellipsis;
            font-size: 14px;
            color: #AAAAAA;
          }
          .need-know-more {
            color: #9F88FF;
          }
        }
        .pay-num-opt {
          align-items: center;
          justify-content: center;
        }
      }
      .shopping-card-none {
        height: 100%;
        font-size: 18px;
        color: #AAAAAA;
        .shopping-card-loading {
          margin-top: 10vh;
        }
      }
    }
    .van-sidebar-item--select::before {
      background-color:#9F88FF;
    }
  }
  .goods-info-body {
    max-height: 50%;
    margin: 20px;
    .goods-info-header {
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      font-weight: bold;
    }
    .goods-info-content {
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }
  }
}

</style>
